/**
 * Navigation Menu CSS 
 *  
 */

.navContainer2{
  display: inline-flex !important;
  color: white !important;
  height:5em;
}

.nav-link {
  color:white !important;
}
  
.navbar{
  width:100% !important;
  border-bottom: solid 1px white !important;
}
  
.nav ul{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  height: 100%;
}
  
.nav a {
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  color: green !important;
}
  
.nav li:hover a{
  background-color: rgb(233, 218, 218) !important;
  color: black !important;
}
  
.nav li:active {
  background-color: rgb(152, 70, 70) !important;
}

.container{
  width: auto !important;
}

.registerBTN{
  background: #e16168 !important;
  border-radius: 3em;
  color: #ffe6c0 !important;
}

@media (max-width: 384px) {
.navbar{
  font-size: small;
  width: 90%;
}

}

.loggedInBanner{
  background-image: url('../images/mhb-symbols-blue.png');
  height: 350px;
  border-radius: 3em;
  border-style: outset;
  border-color: #fde68a;
  padding: 1em;
}