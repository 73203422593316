.userdata{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #d3313a;
    border-radius: 1em;
    font-size: x-small;
}

.rentShareUpdate{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background: #d3313a;

}

.userdataLabel{
    color: white;
    font-size: 18px;
    color:rgb(255, 255, 255);
}
.payments{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.tableHead{
    background-color: black !important;
    color:rgb(255, 255, 255);
    --bs-table-bg:white !important;
    
}

.nopadding{
    padding: 0 !important;
}

.tableheader{
    background: #bf2d34 !important;
    width: auto;
    border: 1px solid #fdda69 !important;
    color: white !important;
    border-radius: 1em !important;
}

.exportDataBTN{
    margin: 1px;
    background-color: #c4454d !important;
    border: 1px solid white !important;
}

.exportOptions{
    border-style: outset;
    border-color: #c4454d;
    border-radius: 1em;
    width: 30%;
    margin: 3em auto 0 auto;
    padding: 1em 0 0 0;
    display: table;
}

.hide{
    display: none;
}

@media (max-width: 1920px) {
    .payments{ 
        display: inline;
        font-size: medium !important;
    }

    .tableBody{
        font-size: medium !important;
    }
}

@media (max-width: 768px) {
    .payments{ 
        display: inline;
        font-size: .7em !important;
    }

    .tableBody{
        font-size: 10px !important;

    }
}

@media (max-width: 490px) {
    .payments{ 
        display: inline;
        font-size: 3px !important;
    }

    .tableBody{
        font-size: 10px !important;
    }
}

@media (max-width: 330px) {
    .payments{ 
        display: inline;
        font-size: 4px !important;
    }

    .tableBody{
        font-size: 2em !important;
    }
}