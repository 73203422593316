/*AWS Login Container CSS Properties */

.login_container    {
    width:auto !important;
    --amplify-components-authenticator-router-background-color:  !important;
    --amplify-components-authenticator-router-border-color: white !important;
    --amplify-components-authenticator-container-width-max: 50% !important;    
}

.amplify-label {
    color: white !important;
}

.amplify-tabs-item[data-state=active] {
    color: rgb(255, 255, 255) !important; 
    background-color: #d14650 !important;

  }
  
.amplify-button--primary {
    color: rgb(255, 255, 255) !important; 
    background-color: #d14650 !important;
}

.amplify-input {
    background: white !important;
}

.amplify-field__show-password{
    background: white !important;  
}

.data-amplify-container{
    width:100% !important;
}

.amplify-button--small {
    color: white !important;
}

/*Public page CSS */

.publicContent{
    padding:0 !important;

}

.infoContainer  {

}

.leftContent{
    margin-left: 3em;
    margin-right: 3em;
}

.rightContent{
    margin-right: 3em;
    margin-right: 3em;
}

.container{

}

.whiteFont{
    color: white;
}

.padding{
    padding-top: 3em;
    padding-bottom: 1em;
}

.paddingBottom{
    padding-bottom: 1em;
}

.paddingLeft{
    padding-left: 3em;
}

.padding-top{
    padding-top: 3em;
}

.empBorder{
    border: 1px solid white;
    border-radius: 1em;
}


.notice{
    color: rgb(249, 242, 229);
    border: 1px solid white;
    border-radius: 1em;
    background-color: rgb(136, 64, 64);
}

.remove{
    display: none;
}


/* CSS For Login Steps Section Below*/

.aptLogo{
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    opacity: .9;
    filter: saturate(0.5);
    
}

.signuplogo{
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    opacity: .9;
}

.emphTitle{
    color: #fde68a!important;
    font-size: 20px !important;
}

.btnColor{
    background:#c60b16 !important;
    width: 50% !important;
    margin-left: auto;
    margin-right: auto;
    color: #fde68a!important;
    font-size: 2em !important;
    border: 1px solid #fde68a !important;
}

.emphTitle2{
    color: #fde68a!important;
}

.m_register{
    display: block !important;
}

.m_gateway{
    margin-top: 2em !important;
    margin-left:1em !important;
}


.login_container    {
    width:auto !important;
    --amplify-components-authenticator-router-background-color:  !important;
    --amplify-components-authenticator-router-border-color: none !important;
    --amplify-components-authenticator-container-width-max: 90% !important;    
}

.UserTips{
    margin-right: 1em;
}

.portalUpdateInfo{
    border-style: outset;
    border-color: #c4454d;
    border-radius: 1em;
    width: 90%;
    margin: 3em auto 0 auto;
    padding: 1em 0 0 0;
}


/* Mobile Devices */

@media (min-width: 30rem) {
    .login_container    {
        width:auto !important;
        --amplify-components-authenticator-router-background-color:  !important;
        --amplify-components-authenticator-router-border-color: none !important;
        --amplify-components-authenticator-container-width-max: 90% !important;    
    }
  }