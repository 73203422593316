/**
 * Navigation Menu CSS 
 *  
 */
 

 .navContainer{
  display: inline-flex !important;
  background-color: #d3313a !important;
  height:5em;
}

.container{
  width: auto !important;
  height: auto !important;
}

.navbar-brand {
  color:white !important; 
}

.navbar{
  width:100% !important;
  background-color: #d3313a;
  font-size: 1em;
}

.nav ul{
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  height: 100%;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav li:hover a{
  background-color: rgb(233, 218, 218) !important;
  color: black !important;
}

.nav li:active {
  background-color: rgb(152, 70, 70) !important;
}

.m_registerBTN{
  
}