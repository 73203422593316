

.m_banner {
    padding:15px;
    background: #e3d7c4;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    text-align: center;
    color: #56565b;
    height: 250px;
    margin: auto;
}