.Footer{
    color:white !important; 
    font-size: .9em;

}

.bg-body-tertiary{
    background-color: #d3313a !important;
    
    --bs-bg-opacity: 1;
}


.navbar-brand {
    color:white !important; 
  }

  .logoFooter{
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;

  }

  @media (max-width: 990px) {
    .logoFooter{ 
      width: 150px !important;
    }
  }
